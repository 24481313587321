.ui-datepicker {
  .ui-datepicker-prev,
  .ui-datepicker-next {
    height: 1.5em;
    background: #fff;
    widows: 32px;
    height: 32px;
  
    &.ui-state-hover {
      border: none;
      border-radius: 4px;
      background-color: #f0f0f0;
    }
  }
  
  .ui-datepicker-prev,
  .ui-datepicker-prev-hover,
  .ui-datepicker-next,
  .ui-datepicker-next-hover {
    right: 0px;
    top: 0px;
  }
  
  .ui-datepicker-prev .ui-icon {
    background: url('../images/left-arrow.png') no-repeat top left;
  }
  .ui-datepicker-next .ui-icon {
    background: url('../images/right-arrow.png') no-repeat top right;
  }

  .ui-datepicker-title select {
    color: #2311b2;
  }
  .ui-datepicker-title div {
    background: url('../images/bottom-arrow.png') no-repeat 55px 12px #efcb01;
  }
  td a.ui-state-active {
    background: #1E23B0;
    color: #FFF;
  }
  td span, td a {
    background: #efcb01;
    color: #2311b2;
  }
  .ui-datepicker-unselectable .ui-state-default {
    background: #efcb01;
    color: #1E23B0;
  }
}

// .ui-datepicker .ui-datepicker-next .ui-icon {
//   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32'><path d='M 14,12 l 5,5 l -5,5'></path></svg>");
//   fill: none;
//   stroke: #9c9c9c;
//   stroke-width: 2px;
// }

.datepicker .datepicker--cell {
  user-select: none;
}

.ui-datepicker-inline {
  background: #fff;

  .ui-datepicker-header {
    width:100%;
    background: #fff;
    border-width: 0 0 1px;
    border-color: transparent transparent #efefef;
    font-weight: normal;
  }
}

.ui-datepicker-title, .ui-datepicker table {
  font-size: 14px;
}

.ui-datepicker-calendar {
  thead {
    font-size: .8em;
  }
  th {
    color: #1C23B2;
    flex: 1;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
  }
  
  .ui-state-hover,
  .ui-datepicker td span,
  .ui-datepicker td a:hover {
    background: #f0f0f0;
  }
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, 
.ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background: #fff;
  line-height: 32px;
  padding: 0px;
  border: none;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  text-align: center;   
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  text-align: center;
}

.ui-datepicker-unselectable span.ui-state-default {
  background:#fff !important;
  color: #EEE !important;
  border:none;
  height: 32px;
}
  
.datepicker{
  border-radius: 4px;
    box-sizing: content-box;
    font-family: Tahoma,sans-serif;
    font-size: 14px;
    color: #4a4a4a;

}
.inline-picker {
  width: 100%;
}

.datepicker--nav{
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}
.datepicker--day-name{
  color: #1C23B2;
}
.datepicker--cell-day{
  width: 14.28571%;
}

.datepicker--cell.-selected-.-current- {
  background: #1C23B2;
}
.datepicker--content{
  box-sizing: content-box;
    padding: 4px;
}

.datepicker--cell.-selected-{
  background: #1C23B2;
}
.datepicker--cell.-selected-.-focus-{
  background: #1C23B2;
}

.datepicker--cell.-selected-,
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-day.-other-month-.-focus-, 
.-selected-.datepicker--cell-year.-other-decade- {
  background:  #1C23B2;
}

