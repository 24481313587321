.blue-title {
    color: #FFF;
    background: #2311b2;
  }
  .blue-title .small {
    color: #FFF;
    background: #2311b2;
  }
  .yellow-title .small {
    color: #2311b2;
    background: #efcb01;
  }
  .blue-title .depart {
    background: url('../images/white-fly.png') no-repeat left 10px;
  }
  .blue-title .return {
    background: url('../images/white-fly.png') no-repeat left 10px;
  }
  .flight-sc .table-header {
    background-color: #373737;
  }
  .ynew-button {
    background: #a38b00;
  }
  .continue-button {
    margin: 0;
  }
  a.rules-close-btn {
    background: #efcb01;
    color: #2311b2;
  }
  .price-cal ul.price-cal-title {
    background: #efcb01;
    color: #2311b2;
  }
  .price-cal-wrap .hr {
    border-bottom: 1px solid #efcb01;
  }
  .price-cal-wrap a.close-btn {
    background: #efcb01;
    color: #2311b2;
  }
  .flight-rules-wrap a.close-btn {
    background: #efcb01;
    color: #2311b2;
  }
  #error_screen a.close-btn {
    background: #efcb01;
    color: #2311b2;
  }
  .price-calculation ul.price-cal-title {
    background: #efcb01;
    color: #2311b2;
  }
  .price-calculation-wrap .hr {
    border-bottom: 1px solid #efcb01;
  }
  .price-calculation-wrap a.close-btn {
    background: #efcb01;
    color: #2311b2;
  }

  .Switch {
    color: #373737;
    background: #eccc00;
  }
  .Switch span.On {
    color: #373737;
  }
  .Switch .Toggle {
    background: #373737;
  }

  .connectionfilter ul li {
    color: #eccc00;
  }

  .y-img-button a.list_view {
    color: #000000;
    background: url('../images/list_view.png') right 8px no-repeat;
  }
  .y-img-button a.gallery_view {
    color: #000000;
    background: url('../images/gallery_view.png') right 8px no-repeat;
  }
  .y-img-button a.map_view {
    color: #000000;
    background: url('../images/map_view.png') right 8px no-repeat;
  }
  .y-img-button {
    background: #efcb01;
  }
  .img-selected {
    background-color: #cec388;
  }

  .banners_horizontal img {
    // width: 802px;
    width: 100%;
  }


.search-result-container {
  display: inline-block;
  width: 65%;
  padding-left: 15px;

  // TODO: Do we want to move this or?
  #filter_no_result {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #FFF;
    font-size: 16px;
    color: #000;
    padding: 20px;
    border: 1px solid #949494;
    margin-bottom: 10px;

    a {
      text-decoration: underline;
      color: #000000;
    }
  }
}

.flight-wrap .resurs {
  float: left;
  margin-top: -3px;
  padding-right: 15px;
}

.flight-wrap .resurs a {
  color: black;
  text-decoration: underline;
}

.flight-wrap .resurs .resurs_text {
  text-align: left;
  font-size: 15px;
  font-weight: 200;
}

.flight-wrap .resurs .resurs_price {
  font-size: 20px;
}

.price-box .pax-price {
  float: left;
  padding-right: 20px;
  margin-top: 5px;
}

.price-box .pax-price .price {
  font-size: 20px
}

.flight-wrap .price {
  font-size: 30px;
  text-align: right;
  margin-bottom: 4px;
  float: right;
}

.time-travel_time {
  font-size: 14px;
}

.time-highlight {
  font-weight: bold;
}

.time-overlay {
  opacity: 0.3;
}
