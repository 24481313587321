div.internet-bank-text {
  color: #999999;
  text-align: left;
  font-size: 14px;
  padding: 5px;
  white-space: nowrap;
}

.searchfilter p.switch a.open {
  background: url('../images/showicon.png') no-repeat left;
}
.searchfilter p.switch a {
  background: url('../images/hideicon.png') no-repeat left;
}
.searchfilter .title {
  background-color: #1866aa;
  // background: #a38b00;
}
.searchfilter input[type="button"] {
  background: #efcb01;
  color: #373737;
}
ul.sort_by li {
  background-color: #e2e2e2;
}
ul.sort_by li:hover {
  background-color: #FFF;
}

.filtersec {
  background-color: #251faf;
  // background: #373737;
}
.filtersec input[type="text"] {
  color: #eccc00;
}
.filter .caption-collapsed {
  background: url('../images/white-right-arrow.png') 0 no-repeat;
}
.filter .caption-open {
  background: url('../images/white-down-arrow.png') 0 no-repeat;
}
.ui-slider {
  background-color: #a38b00;
}

.ui-slider-range {
  background: #eccc00;
}
.rangeslider label {
  color: #eccc00;
}
.airlinefilter ul li {
  color: #eccc00;
}
.ui-widget.ui-widget-content{
  height:12px;
}
