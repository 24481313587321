
// TODO: @import "autocomplete";

$calendar-width: 72%;
$options-width: 100% - $calendar-width;

//New searchfield code


.ui-selectmenu-button{
  color: #2311b2;
  padding: 0 35px 0 10px;
  height: 38px;
  border: 1px solid #2311b2;
  width:100% !important;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 17px;
    font-weight: 300;
    background: #FFF;
    line-height: 38px;
    overflow: hidden;
}

.ui-selectmenu-button:hover{
  background:#fdf5ce;
  color: #2311b2;
  border: 1px solid #2311b2;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 17px;
    font-weight: 300;
}


.ui-selectmenu-button.ui-button .ui-icon{
  position: absolute;
  margin-top: -8px;
  top: 50%;
  width: 18px;
  height: 16px;
  right: 10px;
  background: url('../images/selectlist-icon.png') no-repeat;
}

.ui-button .ui-icon{
  position: absolute;
  margin-top: -8px;
  top: 50%;
  width: 18px;
  height: 16px;
  right: 10px;
  background: url('../images/selectlist-icon.png') no-repeat;
}



#search_airline-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
    border-bottom: 1px solid #b9b9b9;

    font-family: 'Roboto Condensed', sans-serif;
}

#search_cabin-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;

    font-family: 'Roboto Condensed', sans-serif;
}

#search_adults-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;

    font-family: 'Roboto Condensed', sans-serif;
}

#search_youths-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;

}

#search_no_children-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;

}

#search_rooms-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
}
#search_rooms-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
}

@for $i from 1 through 8 {
  #search_child_#{$i}-menu > .ui-menu-item{
    background: #e4e4e4;
    color: #2311b2;
    font-size: 14px;
    border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

@for $i from 1 through 9 {
  #search_youth_#{$i}-menu > .ui-menu-item{
    background: #e4e4e4;
    color: #2311b2;
    font-size: 14px;
    border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

@for $i from 1 through 8 {
  #search_room_#{$i}_adults-menu > .ui-menu-item{
    background: #e4e4e4;
    color: #2311b2;
    font-size: 14px;
    border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

@for $i from 1 through 8 {
  #search_room_#{$i}_no_children-menu > .ui-menu-item{
    background: #e4e4e4;
    color: #2311b2;
    font-size: 14px;
    border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
  }
  }


.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-state-active.ui-button:hover
{
  background: #cdcdcd;
  color: #2311b2;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    padding: 5px 10px;
  //height: auto;
}


.ui-menu .ui-menu-item-wrapper{
  line-height: normal;
    padding: 5px 10px;
    font-size: 14px;
}

.ui-menu-item-wrapper.ui-state-active{
  margin:0;
}

#search_adults-menu{
  height: auto;
  width: 232px !important;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
#search_children-menu{
  height: 242.333px;
  width: 232px;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
#search_youths-menu{
  height: 242.333px;
  width: 232px;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
#search_infants-menu{
  height: 242.333px;
  width: 232px;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.ui-widget-content.ui-autocomplete{
  width: auto;
}
#search_children-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
}
#search_youth-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
}
#search_infants-menu > .ui-menu-item{
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
}

#search_rooms-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
@for $i from 1 through 8 {
#search_room_#{$i}_adults-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
}
#search_no_children-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
#search_youth-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
@for $i from 1 through 8 {
#search_room_#{$i}_no_children-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
}

@for $i from 1 through 8 {
#search_room_1_child_#{$i}-menu{
  height: auto;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
}

@for $i from 1 through 8 {
  #search_child_#{$i}-menu{
    height: 58px;
    padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
  }
}
@for $i from 1 through 9 {
  #search_youth_#{$i}-menu{
    height: 202px;
    padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
  }
}


#search_airline-menu {
  height: 219.667px;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
#search_cabin-menu{
  height:100%;
  padding: 0;
    margin: 0;
    list-style: none;
    position: relative;

}


.ui-button:focus{
  color: #2311b2;
  padding: 0 35px 0 10px;
  height: 38px;
  border: 1px solid #2311b2;
  width:100% !important;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 300;
    background: #FFF;
    line-height: 38px;
    overflow: hidden;
}
.ui-datepicker-inline{
  border-color: #d7d7d7;
    box-shadow: none;
    position: static;
    left: auto;
    right: auto;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    width: 100%;
}

//Old code

.show-booking{
  float: right;
    width: 29%;
    border-radius: 3px;
    border: 1px dashed #2311b2;
    padding: 1%;
    margin: 1%;
    .title{
      border-radius: 3px;
      background: #2311b2;
      color: #FFF;
      text-align: center;
      padding: 10px 5px;
      font-size: 20px;
      margin: 0 0 15px 0;
    }
    .topM10 {
      margin-top: 10px;
    }
    input[type="text"] {
      border-radius: 3px;
      border: 1px solid #2311b2;
      width: 100%;
      height: 38px !important;
      line-height: 38px;
      color: #2311b2;
      font-size: 18px;
      padding: 0 10px;
      float: left;
      margin-top: 10px;
    }
}

.search-field.search-field-small {
  .hide-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }

  /* TEST */
  .buttons {
    margin-top: 15px;
  }
}

.search_box_small {
    max-height: 200px;
    overflow: hidden;
}

#search_box_id {
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  -o-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
}

.search-field {
  padding: 0px 15px 5px 20px;
  font-size: 18px;

  .show-small {
    display: none;
  }

  ul {
    float: left;
    width: 100%;
    margin: 0 0 15px 0;
  }

  ul li {
    float: left;
    width: 47%;
    margin: 0 1% 0 0;

    &.right {
      float: right !important;
      margin: 0 !important;
    }
  }

  .header {
    color: #000;
    font-size: 15px;
    font-weight: 400;
  }

  .ui-selectmenu-status {
    padding: 0 35px 0 10px;
    line-height: 38px;
    overflow: hidden;
  }

  .search-field-left {
    padding-left: 5px;
    width: $calendar-width;
    float: left;

    .quick-destinations {
      margin-top: 5px;
      display: inline-block;

      .quick-link {
        display: inline;
        border-bottom: 1px dotted #000;
        cursor: pointer;
        white-space: nowrap;
        line-height: 24px;
      }
    }

    .single_big {
      width: 96%;
    }

    .multi_add_ul {
      padding-left: 22px;
      padding-top: 10px;
    }

    &.search-field-mutli-city {
      ul li + li + li {
        margin-right: 0;
      }

      ul li {
        width: 28%;

        &.number {
          width: 0.5%;
          padding-top: 27px;
        }

        &.icon {
          width: 4%;
        }

        &.remove {
          width: 4%;
          padding-left: 15px;
          padding-top: 22px;

          .y-button {
            padding: 0 5px;

            a {
              line-height: 27px;
              height: 27px;
            }
          }
        }

        &.small_date {
          padding-left: 25px;
        }
      }
    }


    ul li.icon {
      float: left;
      width: 2%;

      img.right_left_arrow {
        margin-top: 25px;
      }
    }

    input[type="text"] {
      width: 100%;
      height: 38px;
      // float: left
      font-size: 18px;
      padding: 0 10px 0 40px;
      line-height: 38px;
      background-color: #FFF;
      color: var(--border);
      border: 1px solid var(--border);
    }
  }

  .search-field-right {
    width: $options-width;
    padding-right: 10px;
    float: right;

    .rooms {
      width: 100%;
      padding-bottom: 5px;

      li {
        width: 100%;
      }
    }

    .pax {
      ul.room_split {
        padding-top: 15px;
        border-top: 1px solid #a2a2a2;
      }

      li.room_pax {
        float: left;
        width: 38%;
        margin: 0 2% 0 0;
      }

      li.room_no {
          width: 16%;
          padding-top: 10px;
          font-size: 12px;
      }

      .pax_no_fields li {
        padding-bottom: 5px;
      }
    }

    .pax_no_fields li {
      padding-bottom: 5px;
    }

    .facilities {
      margin: 0px;

      li {
        width: 100%;
        padding-bottom: 5px;
      }
    }

    .direct-flight li {
      width: 100%;
      span {
        float: left;
        line-height: 40px;
        padding: 0 0 0 5px;
        cursor: pointer;
      }
    }

    .buttons li {
      margin: 0px;
      float: right;
    }
  }

  .return_fields {
    position: relative;
  }

  .return_overlay {
    height: 100%;
    width: 101%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.9;
    filter: alpha(opacity=90);
    z-index: 10;
    background-color: #FFF;
    text-align: center;
    vertical-align: center;
    display: none;
  }


  input[type="text"].field-1 {
    background: url('../images/fly-icon.png') #FFF no-repeat 10px center;
    // background: cloudinary-url('shared/site/fly-icon.png', $quality: "auto", $fetch_format: "auto") #FFF no-repeat 10px center;
  }
  input[type="text"].field-2 {
    background: url('../images/fly-back-icon.png') #FFF no-repeat 10px center;
    // background: cloudinary-url('shared/site/fly-back-icon.png', $quality: "auto", $fetch_format: "auto") #FFF no-repeat 10px center;
  }
  input[type="text"].field-3 {
    background: url('../images/destination-icon.png') #FFF no-repeat 10px center;
    // background: cloudinary-url('shared/site/destination-icon.png', $quality: "auto", $fetch_format: "auto") #FFF no-repeat 10px center;
  }
  input[type="text"].field-4 {
    background: url('../images/destination-icon.png') #FFF no-repeat 10px center;
    // background: cloudinary-url('shared/site/destination-icon.png', $quality: "auto", $fetch_format: "auto") #FFF no-repeat 10px center;
  }

  label.adults-icon span {
    background: url('../images/adults-icon.png') no-repeat 0px center;
    // background: cloudinary-url('shared/site/adults-icon.png', $quality: "auto", $fetch_format: "auto") no-repeat 0px center;
  }
  label.child-icon span {
    background: url('../images/child-icon.png') no-repeat 0px center;
    // background: cloudinary-url('shared/site/child-icon.png', $quality: "auto", $fetch_format: "auto") no-repeat 0px center;
  }
  label.baby-icon span {
    background: url('../images/baby-icon.png') no-repeat 0px center;
    // background: cloudinary-url('shared/site/baby-icon.png', $quality: "auto", $fetch_format: "auto") no-repeat 0px center;
  }
}

.custom-checkbox-search_only_direct_flights, .custom-checkbox-search_advanced_hotel_search, .custom-checkbox-search_only_with_bags, .custom-checkbox-search_only_private_fares, .custom-checkbox-search_only_public_fares, .custom-checkbox-search_no_ndc, .custom-checkbox-search_only_rebookable, .custom-checkbox-search_max_one_stop {
  background: url('../images/checkbox.png') no-repeat;
  // background: cloudinary-url('shared/site/checkbox.png', $quality: "auto", $fetch_format: "auto") no-repeat;
}
.custom-checkbox-search_only_direct_flights:hover, .custom-checkbox-search_advanced_hotel_search:hover, .custom-checkbox-search_only_with_bags:hover, .custom-checkbox-search_only_private_fares:hover, .custom-checkbox-search_only_public_fares:hover, .custom-checkbox-search_no_ndc:hover, .custom-checkbox-search_only_rebookable:hover, .custom-checkbox-search_max_one_stop:hover {
  background: url('../images/checkbox-selected.png') no-repeat;
  // background: cloudinary-url('shared/site/checkbox-selected.png', $quality: "auto", $fetch_format: "auto") no-repeat;
}
.custom-checkbox-search_only_direct_flights.selected, .custom-checkbox-search_advanced_hotel_search.selected, .custom-checkbox-search_only_with_bags.selected, .custom-checkbox-search_only_private_fares.selected, .custom-checkbox-search_only_public_fares.selected, .custom-checkbox-search_no_ndc.selected, .custom-checkbox-search_only_rebookable.selected, .custom-checkbox-search_max_one_stop.selected {
  background: url('../images/checkbox-selected.png') no-repeat;
  // background: cloudinary-url('shared/site/checkbox-selected.png', $quality: "auto", $fetch_format: "auto") no-repeat;
}
