/* Autocomplete
----------------------------------*/
.ui-helper-hidden-accessible {
  display: none; }

.ui-autocomplete {
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #b9b9b9;
  position: absolute;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px; }

/*----------------------------------*/
.ui-menu {
  list-style: none;
  margin: 0;
  display: block; }

.ui-menu .ui-menu {
  margin-top: -3px; }

.ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  width: 100%; }

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  line-height: normal;
  padding: 5px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: 300;
  word-wrap: break-word; }

.ui-menu .ui-menu-item a:hover {
  background: #FDF5CE; }

.ui-menu .ui-menu-item a.ui-state-hover, .ui-menu .ui-menu-item a.ui-state-active {
  margin: -1px;
  background: #F00 !important; }

.ui-menu-item a {
  display: block; }

.ui-menu-item .name_space {
  padding-left: 15px; }

.ui-menu-item .code {
  color: #949494;
  float: right;
  font-weight: 400; }

.ui-menu-item .space {
  margin-top: 12px; }

.ui-menu .ui-menu-item a:hover .code {
  color: #000000; }

.ui-menu-item .country {
  color: #949494; }

.ui-menu-item .icon_space {
  float: left;
  width: 25px;
  margin-left: 15px; }

.ui-menu-item .icon_low {
  float: left;
  width: 27px;
  padding-top: 10px; }

.ui-menu-item span.highlight {
  font-weight: 500; }
